@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.debug {
  border: 1px solid red;
}




.react-datepicker {
  border: none !important;
}

.react-datepicker__month-container {
  background-color: #272727 !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: white !important;
  border: none !important;
}

.react-datepicker__header {
  background-color: #272727 !important;
  border: none !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: white !important;
  border: none !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #f51f6f !important;
  color: #fff;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #3e3e3e !important;
}


:root {
  --plyr-color-main: #f51f6f
}